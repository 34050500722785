import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import styled from 'styled-components';
import TagCbt from 'src/app.feature/academy/cbt.best/component/tag/TagCbt';
import TagBgImage from 'src/app.feature/academy/component/image/tag-bg.png';
import EyeIconSvg from 'src/app.feature/academy/component/icon/eye-icon.svg';
import FavoriteIconSvg from 'src/app.feature/academy/component/icon/favorite-icon.svg';
import { BestCbt } from 'app.feature/academy/cbt.best/type/BestCbt';
import { useRecoilValue } from 'recoil';
import { projectConfigState } from 'recoil/Academy/projectConfigState';

type CbtSlideProps = {
  cbt: BestCbt;
  index: number; // 인덱스 값 추가
};

const CbtSlide: React.FC<CbtSlideProps> = ({ cbt, index }) => {
  const { logo } = useRecoilValue(projectConfigState);
  return (
    <StyledArticle>
      <header>
        <p className="rank">{index + 1}</p>
        {/*todo: link-wrapper를 Link로 변경*/}
        <div className="link-wrapper">
          <figure>
            {cbt.examUrl ? (
              <Image src={cbt.examUrl} alt={cbt.examName} width={150} height={90} />
            ) : (
              <Image src={logo} alt="Empty" width={150} height={90} />
            )}
            <figcaption className="cbt-title-wrapper">
              <h3 className="cbt-title">{cbt.examName}</h3>
              <div className="cbt-tag">
                {cbt?.examTypes?.map((item: any) => (
                  <TagCbt category={item.name} />
                ))}
              </div>
            </figcaption>
          </figure>
        </div>
      </header>
      <footer>
        <p>
          <EyeIconSvg />
          {Number(cbt.viewCount).toLocaleString()}
        </p>
        <p>
          <FavoriteIconSvg />
          {Number(cbt.likeCount).toLocaleString()}
        </p>
      </footer>
    </StyledArticle>
  );
};

export default CbtSlide;

const StyledArticle = styled.article`
  header {
    .rank {
      position: absolute;
      top: -1px;
      left: -1px;
      font-size: 20px;
      font-weight: 700;
      color: #fff;
      width: 42px;
      height: 45.5px;
      line-height: 40px;
      background-image: url(${TagBgImage.src});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      margin: 0;
    }
    .link-wrapper {
      figure {
        width: 100%;
        margin: 0;
        > img {
          display: block;
          width: 99%;
          height: 200px;
          min-width: 150px;
          min-height: 90px;
          overflow: hidden;
          border: 1px solid #e9ecef;
          border-radius: 5px;
        }
      }
      figcaption {
        .cbt-title {
          height: 48px;
          line-height: 25px;
          margin: 16px 0 10px;
          font-size: 16px;
          font-weight: 700;
          color: #333;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          word-wrap: break-word;
          word-break: auto-phrase;
        }
        .cbt-tag {
          display: flex;
          flex-direction: row;
        }
      }
    }
  }

  footer {
    display: flex;
    justify-content: center;
    gap: 10px;
    p {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #999;
      svg {
        margin-right: 5px;
      }
    }
  }
`;
