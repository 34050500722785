import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import SearchSvg from 'app.feature/academy/component/icon/search.svg';
import { useRouter } from 'next/router';
import { theme } from 'app.styled/theme';
import useResponsive from '../../../../../../utils/OneQuestion/hooks/useResponsive';

const CategorySearch = () => {
  const router = useRouter();
  const inputRef = useRef<HTMLInputElement>(null);
  const { isMobile } = useResponsive(768);

  const handleSearchInput = (keyword: string) => {
    router.push({
      pathname: '/',
      query: { ...router.query, keyword },
    });
  };

  useEffect(() => {
    if (inputRef.current) inputRef.current.value = (router.query?.keyword as string) ?? '';
  }, []);

  return (
    <SearchWrapper>
      <SearchSvg />
      <input
        ref={inputRef}
        type="text"
        placeholder={isMobile ? '검색' : '시행기관, 시험명 검색'}
        onChange={(e) => handleSearchInput(e.target.value)}
      />
    </SearchWrapper>
  );
};

export default CategorySearch;

const SearchWrapper = styled.div`
  text-align: right;
  width: 30%;
  max-width: 320px;
  height: 50px;
  box-sizing: border-box;
  border: 1px solid #e9ecef;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0 0 0 15px;
  @media ${theme.device.mobile} {
    width: 100%;
    border: 1px solid #e8e8e8;
    background-color: #fff;
    height: 36px;
    margin: 0 3% 0 5%;
    padding: 0 0 0 12px;
  }
  svg {
    width: 18px;
    height: 16px;
    margin-right: 10px;
    @media ${theme.device.mobile} {
    }
  }

  input {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: none;
    padding: 0;
    border-radius: 5px;
    &::placeholder {
      font-size: 15px;
      color: #888;
      @media ${theme.device.mobile} {
        font-size: 12px;
      }
    }
  }
`;
